import React from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavBar } from "../../components/NavBar";
import { Banner } from "../../components/Banner";
import { Skills } from "../../components/Skills";
import { Projects } from "../../components/Projects";
import { Contact } from "../../components/Contact";
import { Footer } from "../../components/Footer";

function Home () {

    return (
        <div className="home">
            <NavBar />
            <Banner />
            <Skills />
            <Projects />
            <Contact />
            <Footer />
        </div>
    );

}

export default Home;